import { GroupUser } from 'processdelight-angular-components';
import { Lookup } from "./lookup";

export class PermanenceUser {
  ishtarPermanencePermanenceUserId!: string;
  permanence!: Lookup;
  user!: GroupUser;
  ranking?: number;
  isInExchange?: boolean;
  comment?: string;

  constructor(obj: Partial<PermanenceUser>) {
    Object.assign(this, obj);
  }
}
