import * as fromAbsenceType from './absence-type/absence-type.reducer';
import * as fromAbsence from './absence/absence.reducer';
import * as fromApplicationNotification from './applicationNotification/applicationNotification.reducer';
import * as fromContactDetail from './contactDetail/contactDetail.reducer';
import * as fromContactDetailParam from './contactDetailParam/contactDetailParam.reducer';
import * as fromContactDetailParamValue from './contactDetailParamValue/contactDetailParamValue.reducer';
import * as fromDepartment from './department/department.reducer';
import * as fromPermanenceDuty from './permanenceDuty/permanenceDuty.reducer';
import * as fromPermanenceTemplate from './permanenceTemplate/permanenceTemplate.reducer';
import * as fromRecurrence from './recurrence/recurrence.reducer';

import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { AbsenceTypeEffects } from './absence-type/absence-type.effects';
import { AbsenceEffects } from './absence/absence.effects';
import { ApplicationNotificationEffects } from './applicationNotification/applicationNotification.effect';
import { ContactDetailEffects } from './contactDetail/contactDetail.effects';
import { ContactDetailParamEffects } from './contactDetailParam/contactDetailParam.effects';
import { ContactDetailParamValueEffects } from './contactDetailParamValue/contactDetailParamValue.effects';
import { DepartmentEffects } from './department/department.effects';
import { PermanenceDutyEffects } from './permanenceDuty/permanenceDuty.effects';
import { PermanenceTemplateEffects } from './permanenceTemplate/permanenceTemplate.effects';
import { RecurrenceEffects } from './recurrence/recurrence.effects';

export const featureKey = 'coreFeature';

export interface CoreState {
  [fromContactDetail.featureSlice]: fromContactDetail.State;
  [fromContactDetailParam.featureSlice]: fromContactDetailParam.State;
  [fromContactDetailParamValue.featureSlice]: fromContactDetailParamValue.State;
  [fromDepartment.featureSlice]: fromDepartment.State;
  [fromAbsenceType.featureSlice]: fromAbsenceType.State;
  [fromApplicationNotification.featureSlice]: fromApplicationNotification.State;
  [fromPermanenceTemplate.featureSlice]: fromPermanenceTemplate.State;
  [fromPermanenceDuty.featureSlice]: fromPermanenceDuty.State;
  [fromRecurrence.featureSlice]: fromRecurrence.State;
  [fromAbsence.featureSlice]: fromAbsence.State;
}

export const reducers = new InjectionToken<ActionReducerMap<CoreState>>(
  featureKey,
  {
    factory: () => ({
      [fromContactDetail.featureSlice]: fromContactDetail.Reducer,
      [fromContactDetailParam.featureSlice]: fromContactDetailParam.Reducer,
      [fromContactDetailParamValue.featureSlice]:
        fromContactDetailParamValue.Reducer,
      [fromDepartment.featureSlice]: fromDepartment.Reducer,
      [fromAbsenceType.featureSlice]: fromAbsenceType.Reducer,
      [fromPermanenceTemplate.featureSlice]: fromPermanenceTemplate.Reducer,
      [fromPermanenceDuty.featureSlice]: fromPermanenceDuty.Reducer,
      [fromRecurrence.featureSlice]: fromRecurrence.Reducer,
      [fromAbsence.featureSlice]: fromAbsence.Reducer,
      [fromApplicationNotification.featureSlice]:
        fromApplicationNotification.Reducer,
    }),
  }
);

export const effects = [
  ContactDetailEffects,
  ContactDetailParamEffects,
  ContactDetailParamValueEffects,
  DepartmentEffects,
  AbsenceTypeEffects,
  PermanenceTemplateEffects,
  PermanenceDutyEffects,
  RecurrenceEffects,
  AbsenceEffects,
  ApplicationNotificationEffects,
];
