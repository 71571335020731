import { createAction, props } from '@ngrx/store';
import { Recurrence } from '../../models/project/recurrence';
import { ActionBase } from '../action-base.interface';
import { PermanenceDuty } from '../../models/project/permanenceDuty';

export const getRecurrencies = createAction(
  '[Recurrence] Get recurrencies',
  props<{
    callback?: (recurrencies: Recurrence[]) => void;
  }>()
);

export const getRecurrenciesResolved = createAction(
  '[Recurrence] Get recurrencies resolved',
  props<{
    result: Recurrence[];
  }>()
);

export const addRecurrence = createAction(
  '[Recurrence] Add recurrence',
  props<
    {
      recurrence: Recurrence;
      calendarStartDate: Date;
      calendarEndDate: Date;
    } & ActionBase<Recurrence>
  >()
);

export const addRecurrenceResolved = createAction(
  '[Recurrence] Add recurrence resolved',
  props<{ addedRecurrence: Recurrence }>()
);

export const updateRecurrence = createAction(
  '[Recurrence] Update recurrence',
  props<
    {
      recurrence: Recurrence;
      startDate: Date;
      endDate: Date;
    } & ActionBase<{
      updatedRecurrency: Recurrence;
      recurrencyDuties: PermanenceDuty[];
    }>
  >()
);

export const updateRecurrenceResolved = createAction(
  '[Recurrence] Update recurrence resolved',
  props<{ updatedRecurrency: Recurrence }>()
);

export const removeRecurrence = createAction(
  '[Recurrence] Remove recurrence',
  props<
    {
      ishtarPermanenceRecurrencyId: string;
    } & ActionBase<{
      deletedPermanenceIds: string[];
      deletedRecurrencyId: string;
    }>
  >()
);

export const removeRecurrenceResolved = createAction(
  '[Recurrence] Remove recurrence resolved',
  props<{
    deletedRecurrencyId: string;
  }>()
);
