import { DateTime } from 'luxon';
import { PermanenceDuty } from './permanenceDuty';
import { GroupUser } from 'processdelight-angular-components';

export class Exchange {
  ishtarPermanenceExchangeId!: string;
  permanenceDuty!: PermanenceDuty;
  user?: GroupUser;
  startDate?: Date;
  endDate?: Date;
  approved!: boolean;

  constructor(obj: Partial<Exchange>) {
    Object.assign(this, obj);
    if (typeof this.startDate == 'string') {
      this.startDate = DateTime.fromFormat(this.startDate, 'd/M/yyyy H:m', {
        zone: 'utc',
      }).toJSDate();
    }

    if (typeof this.endDate == 'string') {
      this.endDate = DateTime.fromFormat(this.endDate, 'd/M/yyyy H:m', {
        zone: 'utc',
      }).toJSDate();
    }

    if (obj.permanenceDuty) {
      this.permanenceDuty = new PermanenceDuty(obj.permanenceDuty);
    }
  }
}
