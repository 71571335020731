import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from 'src/app/shared/store/app.reducer';
import { Absence } from '../../models/project/absence';
import {
  addAbsencesResolved,
  removeAbsencesResolved,
  updateAbsencesResolved,
  getAbsencesResolved,
  getAbsencesForUserResolved,
  getAbsencePeriodsResolved,
  addAbsencePeriodsResolved,
  updateAbsencePeriodsResolved,
  removeAbsencePeriodsResolved,
} from './absence.actions';
import { AbsencePeriod } from '../../models/project/absencePeriod';

export const featureSlice = 'absence';

export interface State {
  absences?: Absence[];
  myAbsences?: Absence[];
  absencePeriods?: AbsencePeriod[];
  absence?: Absence;
  ishtarPermanenceAbsenceId?: string;
}

const defaultState: State = {
  absences: undefined,
  myAbsences: undefined,
  absencePeriods: undefined,
  absence: undefined,
  ishtarPermanenceAbsenceId: undefined,
};

export function Reducer(state: State | undefined, action: Action) {
  return absenceReducer(state, action);
}

export const initialState: State = defaultState;
export const absenceReducer = createReducer(
  initialState,
  on(getAbsencesResolved, (state, { result }) => ({
    ...state,
    absences: state.absences
      ? [...state.absences!]
          .filter(
            (absence) =>
              !result
                ?.map((r) => r.ishtarPermanenceAbsenceId)
                .includes(absence.ishtarPermanenceAbsenceId)
          )
          .concat([...result])
      : [...result],
  })),
  on(updateAbsencesResolved, (state, { updatedAbsence }) => ({
    ...state,
    absences: state.absences?.map((p) =>
      p.ishtarPermanenceAbsenceId == updatedAbsence.ishtarPermanenceAbsenceId
        ? updatedAbsence
        : p
    ),
    myAbsences: state.myAbsences?.map((p) =>
      p.ishtarPermanenceAbsenceId == updatedAbsence.ishtarPermanenceAbsenceId
        ? updatedAbsence
        : p
    ),
  })),
  on(addAbsencesResolved, (state, { addedAbsence }) => ({
    ...state,
    absences: [...state.absences!].concat([addedAbsence]),
    myAbsences: [...state.myAbsences!].concat([addedAbsence]),
  })),
  on(removeAbsencesResolved, (state, { ishtarPermanenceAbsenceIds }) => ({
    ...state,
    absences: state.absences?.filter(
      (s) => !ishtarPermanenceAbsenceIds.includes(s.ishtarPermanenceAbsenceId!)
    ),
    myAbsences: state.myAbsences?.filter(
      (s) => !ishtarPermanenceAbsenceIds.includes(s.ishtarPermanenceAbsenceId!)
    ),
  })),
  on(getAbsencesForUserResolved, (state, { result }) => ({
    ...state,
    myAbsences: [...result],
  })),
  on(getAbsencePeriodsResolved, (state, { result }) => ({
    ...state,
    absencePeriods: [...result],
  })),
  on(addAbsencePeriodsResolved, (state, { addedAbsencePeriods }) => ({
    ...state,
    absencePeriods: [...state.absencePeriods!].concat(addedAbsencePeriods),
  })),
  on(updateAbsencePeriodsResolved, (state, { updatedAbsencePeriods }) => ({
    ...state,
    absencePeriods: state.absencePeriods?.map((p) =>
      updatedAbsencePeriods
        .map((u) => u.ishtarPermanenceAbsencePeriodId)
        .includes(p.ishtarPermanenceAbsencePeriodId)
        ? updatedAbsencePeriods.find(
            (u) =>
              u.ishtarPermanenceAbsencePeriodId ==
              p.ishtarPermanenceAbsencePeriodId
          )!
        : p
    ),
  })),
  on(
    removeAbsencePeriodsResolved,
    (state, { ishtarPermanenceAbsencePeriodIds }) => ({
      ...state,
      absencePeriods: state.absencePeriods?.filter(
        (s) =>
          !ishtarPermanenceAbsencePeriodIds.includes(
            s.ishtarPermanenceAbsencePeriodId!
          )
      ),
    })
  )
);

export const absenceState = (state: AppState) => state.coreFeature.absence;
