import { GroupUser } from 'processdelight-angular-components';

export class Department {
  ishtarPermanenceDepartmentId!: string;
  name!: string;
  supervisor?: GroupUser;
  phoneNumber?: string;
  users: GroupUser[] = [];
  isDeleted?: boolean;

  constructor(obj: Partial<Department>) {
    Object.assign(this, obj);
  }
}
