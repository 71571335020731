import { DateTime } from 'luxon';

export class AbsencePeriod {
  ishtarPermanenceAbsencePeriodId!: string;
  title!: string;
  startDate?: Date;
  endDate?: Date;
  limit?: number;

  constructor(obj: Partial<AbsencePeriod>) {
    Object.assign(this, obj);

    if (typeof this.startDate == 'string') {
      this.startDate = DateTime.fromFormat(this.startDate, 'd/M/yyyy H:m', {
        zone: 'utc',
      }).toJSDate();
    }

    if (typeof this.endDate == 'string') {
      this.endDate = DateTime.fromFormat(this.endDate, 'd/M/yyyy H:m', {
        zone: 'utc',
      }).toJSDate();
    }
  }
}
