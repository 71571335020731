import { DateTime } from 'luxon';
import { RecurrenceTypes } from '../enums/recurrenceType';
import { Lookup } from './lookup';
import { GroupUser } from 'processdelight-angular-components';
import { RecurrenceDay } from './recurrenceDay';
import { RecurrenceMonth } from './recurrenceMonth';
import { PermanenceUser } from './permanenceUser';
import { RecurrenceRanking } from './recurrenceRanking';

export class Recurrence {
  ishtarPermanenceRecurrencyId!: string;
  permanenceId?: string;
  type?: RecurrenceTypes;
  interval!: number;
  amount?: number;
  startDate!: Date;
  endDate!: Date;
  isDeleted!: boolean;
  frequence!: Lookup;
  department!: Lookup;
  recurrencyDays?: RecurrenceDay[];
  recurrencyMonths?: RecurrenceMonth[];
  user?: GroupUser;
  backups?: PermanenceUser[];
  recurrencyRanking?: RecurrenceRanking;
  fromDateTime?: Date;
  untilDateTime?: Date;

  constructor(obj: Partial<Recurrence>) {
    Object.assign(this, obj);
    if (typeof this.fromDateTime == 'string') {
      this.fromDateTime = DateTime.fromFormat(
        this.fromDateTime,
        'dd/MM/yyyy HH:mm',
        { zone: 'utc' }
      ).toJSDate();
    }

    if (typeof this.untilDateTime == 'string') {
      this.untilDateTime = DateTime.fromFormat(
        this.untilDateTime,
        'dd/MM/yyyy HH:mm',
        { zone: 'utc' }
      ).toJSDate();
    }
    if (typeof this.endDate == 'string') {
      this.endDate = DateTime.fromFormat(this.endDate, 'dd/MM/yyyy HH:mm', {
        zone: 'utc',
      }).toJSDate();
    }

    if (typeof this.startDate == 'string') {
      this.startDate = DateTime.fromFormat(this.startDate, 'dd/MM/yyyy HH:mm', {
        zone: 'utc',
      }).toJSDate();
    }
  }
}
