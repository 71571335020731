import { RecurrenceTypes } from '../enums/recurrenceType';

export class RecurrenceFrequency {
  ishtarPermanenceRecurrencyFrequencyId!: string;
  name!: RecurrenceTypes;

  constructor(obj: Partial<RecurrenceFrequency>) {
    Object.assign(this, obj);
  }
}
