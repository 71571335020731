import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from 'src/app/shared/store/app.reducer';
import { AbsenceType } from '../../models/project/absenceType';
import {
  addAbsenceTypeResolved,
  getAbsenceTypesResolved,
  removeAbsenceTypeResolved,
  updateAbsenceTypeResolved,
} from './absence-type.actions';

export const featureSlice = 'absenceType';

export interface State {
  absenceTypes?: AbsenceType[];
  absenceType?: AbsenceType;
  ishtarPermanenceAbsenceTypeId?: string;
}

const defaultState: State = {
  absenceTypes: undefined,
  absenceType: undefined,
  ishtarPermanenceAbsenceTypeId: undefined,
};

export function Reducer(state: State | undefined, action: Action) {
  return absenceTypeReducer(state, action);
}

export const initialState: State = defaultState;
export const absenceTypeReducer = createReducer(
  initialState,
  on(getAbsenceTypesResolved, (state, { result }) => ({
    ...state,
    absenceTypes: [...result],
  })),
  on(updateAbsenceTypeResolved, (state, { absenceTypes }) => ({
    ...state,
    absenceTypes: state.absenceTypes?.map(
      (p) =>
        absenceTypes.find(
          (u) =>
            p.ishtarPermanenceAbsenceTypeId === u.ishtarPermanenceAbsenceTypeId
        ) ?? p
    ),
  })),
  on(addAbsenceTypeResolved, (state, { addedAbsenceTypes }) => ({
    ...state,
    absenceTypes: addedAbsenceTypes.concat([...(state.absenceTypes ?? [])]),
  })),
  on(
    removeAbsenceTypeResolved,
    (state, { ishtarPermanenceAbsenceTypeIds }) => ({
      ...state,
      absenceTypes: state.absenceTypes?.filter(
        (s) =>
          !ishtarPermanenceAbsenceTypeIds.includes(
            s.ishtarPermanenceAbsenceTypeId!
          )
      ),
    })
  )
);

export const absenceTypeState = (state: AppState) =>
  state.coreFeature.absenceType;
