import { DateTime } from 'luxon';
import { GroupUser } from 'processdelight-angular-components';
import { Lookup } from './lookup';
import { PermanenceUser } from './permanenceUser';

export class PermanenceDuty {
  ishtarPermanenceId!: string;
  fromDateTime?: Date;
  untilDateTime?: Date;
  user!: GroupUser;
  isInExchange?: boolean;
  recurrency?: Lookup;
  recurrencyOccurence?: number;
  backups?: PermanenceUser[];
  isDeleted?: boolean;
  department!: Lookup;
  id?: string;
  comment?: string;

  constructor(obj: Partial<PermanenceDuty>) {
    Object.assign(this, obj);
    if (typeof this.fromDateTime == 'string') {
      this.fromDateTime = DateTime.fromFormat(
        this.fromDateTime,
        'd/M/yyyy H:m',
        { zone: 'utc' }
      ).toJSDate();
    }

    if (typeof this.untilDateTime == 'string') {
      this.untilDateTime = DateTime.fromFormat(
        this.untilDateTime,
        'd/M/yyyy H:m',
        { zone: 'utc' }
      ).toJSDate();
    }

    if (obj.backups) {
      this.backups = obj.backups.map((x) => new PermanenceUser(x));
    }
  }
}
