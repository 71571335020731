import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from 'src/app/shared/store/app.reducer';
import { Department } from '../../models/project/department';
import {
  addDepartmentResolved,
  getDepartmentsResolved,
  removeDepartmentResolved,
  updateDepartmentResolved,
} from './department.actions';

export const featureSlice = 'department';

export interface State {
  departments?: Department[];
  department?: Department;
  ishtarPermanenceDepartmentId?: string;
}

const defaultState: State = {
  departments: undefined,
  department: undefined,
  ishtarPermanenceDepartmentId: undefined,
};

export function Reducer(state: State | undefined, action: Action) {
  return departmentReducer(state, action);
}

export const initialState: State = defaultState;
export const departmentReducer = createReducer(
  initialState,
  on(getDepartmentsResolved, (state, { result }) => ({
    ...state,
    departments: [...result],
  })),
  on(updateDepartmentResolved, (state, { departments }) => ({
    ...state,
    departments: !departments[0].isDeleted
      ? state.departments?.map(
          (p) =>
            departments.find(
              (u) =>
                p.ishtarPermanenceDepartmentId ===
                u.ishtarPermanenceDepartmentId
            ) ?? p
        )
      : state.departments?.filter(
          (s) =>
            s.ishtarPermanenceDepartmentId !=
            departments[0].ishtarPermanenceDepartmentId
        ),
  })),
  on(addDepartmentResolved, (state, { addedDepartments }) => ({
    ...state,
    departments: addedDepartments.concat([...(state.departments ?? [])]),
  })),
  on(removeDepartmentResolved, (state, { ishtarPermanenceDepartmentIds }) => ({
    ...state,
    departments: state.departments?.filter(
      (s) =>
        !ishtarPermanenceDepartmentIds.includes(s.ishtarPermanenceDepartmentId!)
    ),
  }))
);

export const departmentState = (state: AppState) =>
  state.coreFeature.department;
