import { Lookup } from './lookup';
import { DateTime } from 'luxon';

export class PermanenceTemplate {
  ishtarPermanenceTemplateId!: string;
  fromDay!: Lookup;
  untilDay!: Lookup;
  fromTime?: Date;
  untilTime?: Date;
  department!: Lookup;
  ranking!: number;
  startDate!: Date;

  constructor(obj: Partial<PermanenceTemplate>, doMapping = true) {
    Object.assign(this, obj);

    if (doMapping) {
      if (typeof this.fromTime == 'string') {
        this.fromTime = DateTime.fromFormat(this.fromTime, 'dd/MM/yyyy HH:mm', {
          zone: 'utc',
        }).toJSDate();
      }

      if (typeof this.untilTime == 'string') {
        this.untilTime = DateTime.fromFormat(
          this.untilTime,
          'dd/MM/yyyy HH:mm',
          {
            zone: 'utc',
          }
        ).toJSDate();
      }

      if (typeof this.startDate == 'string') {
        this.startDate = DateTime.fromFormat(
          this.startDate,
          'dd/MM/yyyy HH:mm',
          {
            zone: 'utc',
          }
        ).toJSDate();
      }
      const fromDay = DateTime.fromFormat(this.fromDay.name!, 'EEEE', {
        zone: 'utc',
      }).toJSDate();
      const untilDay = DateTime.fromFormat(this.untilDay.name!, 'EEEE', {
        zone: 'utc',
      }).toJSDate();
      this.fromTime = new Date(
        Date.UTC(
          fromDay.getUTCFullYear(),
          fromDay.getUTCMonth(),
          fromDay.getUTCDate(),
          this.fromTime!.getUTCHours(),
          this.fromTime?.getUTCMinutes()
        )
      );

      this.untilTime = new Date(
        Date.UTC(
          untilDay.getUTCFullYear(),
          untilDay.getUTCMonth(),
          untilDay.getUTCDate(),
          this.untilTime!.getUTCHours(),
          this.untilTime?.getUTCMinutes()
        )
      );
    }
  }
}
