import { createAction, props } from '@ngrx/store';
import { Absence } from '../../models/project/absence';
import { ActionBase } from '../action-base.interface';
import { AbsencePeriod } from '../../models/project/absencePeriod';

export const getAbsences = createAction(
  '[Absence] Get absences',
  props<{
    startDate: Date;
    endDate: Date;
    callback?: (absences: Absence[]) => void;
  }>()
);

export const getAbsencesForUser = createAction(
  '[Absence] Get absences for user',
  props<{
    callback?: (absences: Absence[]) => void;
  }>()
);

export const getAbsencesForUserResolved = createAction(
  '[Absence] Get absences for user resolved',
  props<{
    result: Absence[];
  }>()
);

export const getAbsencesResolved = createAction(
  '[Absence] Get absences resolved',
  props<{
    result: Absence[];
  }>()
);

export const addAbsences = createAction(
  '[Absence] Add absence',
  props<
    {
      absence: Absence;
    } & ActionBase<Absence>
  >()
);

export const addAbsencesResolved = createAction(
  '[Absence] Add absence resolved',
  props<{ addedAbsence: Absence }>()
);

export const updateAbsences = createAction(
  '[Absence] Update absence',
  props<
    {
      absence: Absence;
    } & ActionBase<Absence>
  >()
);

export const updateAbsencesResolved = createAction(
  '[Absence] Update absence resolved',
  props<{ updatedAbsence: Absence }>()
);

export const removeAbsences = createAction(
  '[Absence] Remove absence',
  props<
    {
      ishtarPermanenceAbsenceId: string;
    } & ActionBase<string[]>
  >()
);

export const removeAbsencesResolved = createAction(
  '[Absence] Remove absences resolved',
  props<{
    ishtarPermanenceAbsenceIds: string[];
  }>()
);

// Absence Periods

export const getAbsencePeriods = createAction(
  '[Absence] Get absence periods',
  props<{
    callback?: (absencePeriods: AbsencePeriod[]) => void;
  }>()
);

export const getAbsencePeriodsResolved = createAction(
  '[Absence] Get absence periods resolved',
  props<{
    result: AbsencePeriod[];
  }>()
);

export const addAbsencePeriods = createAction(
  '[Absence] Add absence period',
  props<
    {
      absencePeriods: AbsencePeriod[];
    } & ActionBase<AbsencePeriod[]>
  >()
);

export const addAbsencePeriodsResolved = createAction(
  '[Absence] Add absence period resolved',
  props<{ addedAbsencePeriods: AbsencePeriod[] }>()
);

export const updateAbsencePeriods = createAction(
  '[Absence] Update absence period',
  props<
    {
      absencePeriods: AbsencePeriod[];
    } & ActionBase<AbsencePeriod[]>
  >()
);

export const updateAbsencePeriodsResolved = createAction(
  '[Absence] Update absence period resolved',
  props<{ updatedAbsencePeriods: AbsencePeriod[] }>()
);

export const removeAbsencePeriods = createAction(
  '[Absence] Remove absence period',
  props<
    {
      ishtarPermanenceAbsencePeriodIds: string[];
    } & ActionBase<string[]>
  >()
);

export const removeAbsencePeriodsResolved = createAction(
  '[Absence] Remove absence period resolved',
  props<{
    ishtarPermanenceAbsencePeriodIds: string[];
  }>()
);
