import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { EffectsRootModule } from '@ngrx/effects';
import { StoreRootModule } from '@ngrx/store';
import {
  ContextMenuAction,
  MicrosoftAuthenticationGuard,
  SideBarAction,
  TopLayoutComponent,
} from 'processdelight-angular-components';
import { Subject, filter, first, map, of, switchMap } from 'rxjs';
import {
  varappconfig,
  varlicense$,
  vartranslations$,
} from 'src/app/core/services/startup.service';
import { environment } from 'src/environments/environment';
import { CoreModule } from './core.module';
import { ContactDetailFacade } from './core/store/contactDetail/contactDetail.facade';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { MaskitoOptions } from '@maskito/core';
import { maskitoTimeOptionsGenerator } from '@maskito/kit';

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    CoreModule,
    EffectsRootModule,
    StoreRootModule,
    MatLuxonDateModule,
    TopLayoutComponent,
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'IshtarPermanence';
  orgLogo$ = varlicense$.pipe(map((o) => o?.logo));
  appLogo = environment.cdnAppIconUrl.replace('color', 'Blauw_Wit');
  userName$ = varlicense$.pipe(map((u) => u?.name));
  userPhoto$ = varlicense$.pipe(map((u) => u?.photo));
  navColor$ = varlicense$.pipe(map((u) => u?.navColor));
  navContrast$ = varlicense$.pipe(map((u) => u?.navContrast));
  translations = vartranslations$.value;
  license = varlicense$.value;
  destroy$ = new Subject<void>();
  isSaving = new Subject<string>();
  contactDetails$ = this.contactDetailFacade.contactDetails$;

  sideBarActions: SideBarAction[] = [];
  iconActions: ContextMenuAction<unknown>[] = [];
  constructor(
    private contactDetailFacade: ContactDetailFacade,
    private router: Router
  ) {}

  static readonly MaskitoOptions: MaskitoOptions = maskitoTimeOptionsGenerator({
    mode: 'HH:MM',
    timeSegmentMaxValues: { hours: Infinity },
  });

  ngOnInit(): void {
    vartranslations$
      .pipe(
        filter((t) => Object.keys(t).length > 0),
        first()
      )
      .subscribe((t) => {
        varlicense$
          .pipe(
            filter((l) => !!l && !!Object.keys(l).length),
            first()
          )
          .subscribe((l) => {
            l?.licenses.some(
              (i) => i.productName == 'Ishtar.Permanence' && i.isAdmin
            )
              ? this.iconActions.push(
                  new ContextMenuAction({
                    label: t?.settings,
                    icon: 'settings',
                    iconOutline: true,
                    action: () =>
                      this.router.navigate(['settings', 'contact-details']),
                  })
                )
              : undefined;
            document.documentElement.style.setProperty(
              '--nav-color',
              l?.navColor ?? '#fff'
            );
            document.documentElement.style.setProperty(
              '--nav-contrast',
              l?.navContrast ?? '#000'
            );
          });
        this.sideBarActions.push(
          new SideBarAction({
            title: t.overview,
            icon: 'overview',
            iconOutline: true,
            isSymbol: true,
            route: ['overview'],
          }),
          new SideBarAction({
            title: t.myDuties,
            icon: 'assignment_ind',
            iconOutline: true,
            route: ['my-duties'],
          }),
          new SideBarAction({
            title: t?.permanenceCalendar,
            icon: 'event_available',
            iconOutline: true,
            route: ['permanence-calendar'],
          }),
          new SideBarAction({
            title: t?.contacts,
            icon: 'contact_phone',
            iconOutline: true,
            route: ['contacts'],
          })
        );
        varappconfig
          .pipe(
            filter((l) => !!l && !!Object.keys(l).length),
            first()
          )
          .subscribe((a) => {
            a?.absencesEnabled
              ? this.sideBarActions.push(
                  new SideBarAction({
                    title: t?.myAbsences,
                    icon: 'calendar_today',
                    iconOutline: true,
                    route: ['my-absences'],
                  }),
                  new SideBarAction({
                    title: t?.absenceCalendar,
                    icon: 'event_busy',
                    iconOutline: true,
                    route: ['absence-calendar'],
                  })
                )
              : undefined;
          });
      });

    const params = new URLSearchParams(location.search);
    if (params.has('appColor'))
      this.appLogo = environment.cdnAppIconUrl.replace(
        'color',
        params.get('appColor')!
      );
    const tabIconLink = document.querySelector(
      "link[rel~='icon']"
    ) as HTMLLinkElement;
    if (tabIconLink) tabIconLink.href = this.appLogo;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

export const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('processdelight-angular-components').then((m) => m.MsalModule),
  },
  {
    path: '404',
    loadComponent: () =>
      import('./not-found/not-found.component').then(
        (m) => m.NotFoundComponent
      ),
  },
  {
    path: 'permanence-calendar',
    canActivate: [MicrosoftAuthenticationGuard],
    loadComponent: () =>
      import('./permanence-calendar/permanence-calendar.component').then(
        (mod) => mod.PermanenceCalendarComponent
      ),
    data: {
      breadcrumbTitle: vartranslations$.pipe(map((u) => u?.permanenceCalendar)),
    },
  },
  {
    path: 'my-duties',
    canActivate: [MicrosoftAuthenticationGuard],
    loadComponent: () =>
      import('./my-duties/my-duties.component').then(
        (mod) => mod.MyDutiesComponent
      ),
    data: {
      breadcrumbTitle: 'My duties',
    },
  },
  {
    path: 'my-absences',
    canActivate: [
      MicrosoftAuthenticationGuard,
      () =>
        varappconfig.pipe(
          first(),
          switchMap((a) => (a?.absencesEnabled ? of(true) : of(false)))
        ),
    ],
    loadComponent: () =>
      import('./my-absences/my-absences.component').then(
        (mod) => mod.MyAbsencesComponent
      ),
    data: {
      breadcrumbTitle: vartranslations$.pipe(map((u) => u?.myAbsences)),
    },
  },
  {
    path: 'absence-calendar',
    canActivate: [
      MicrosoftAuthenticationGuard,
      () =>
        varappconfig.pipe(
          first(),
          switchMap((a) => (a?.absencesEnabled ? of(true) : of(false)))
        ),
    ],
    loadComponent: () =>
      import('./absence-calendar/absence-calendar.component').then(
        (mod) => mod.AbsenceCalendarComponent
      ),
    data: {
      breadcrumbTitle: vartranslations$.pipe(map((u) => u?.absenceCalendar)),
    },
  },
  {
    path: 'overview',
    canActivate: [MicrosoftAuthenticationGuard],
    loadComponent: () =>
      import('./overview/overview.component').then(
        (mod) => mod.OverviewComponent
      ),
    data: { breadcrumbTitle: vartranslations$.pipe(map((u) => u?.overview)) },
  },
  {
    path: 'contacts',
    canActivate: [MicrosoftAuthenticationGuard],
    loadComponent: () =>
      import('./contacts/contacts.component').then(
        (mod) => mod.ContactsComponent
      ),
    data: { breadcrumbTitle: vartranslations$.pipe(map((u) => u?.contacts)) },
  },
  {
    path: 'settings',
    canActivate: [
      MicrosoftAuthenticationGuard,
      () => {
        const router = inject(Router);
        return varlicense$.pipe(
          map((l) =>
            l?.licenses.some(
              (x) => x.productName == 'Ishtar.Permanence' && x.isAdmin
            )
              ? true
              : router.createUrlTree(['overview'])
          )
        );
      },
    ],
    loadChildren: () =>
      import('./settings/settings.component').then((m) => m.settingsRoutes),
    loadComponent: () =>
      import('./settings/settings.component').then(
        (mod) => mod.SettingsComponent
      ),
    // data: { breadcrumbTitle: vartranslations$.pipe(map((u) => u?.settings)) },
    // children: [
    //   {
    //     path: 'contact-details',
    //     loadComponent: () =>
    //       import('./settings/contact-details/contact-details.component').then(
    //         (mod) => mod.ContactDetailsComponent
    //       ),
    //     data: {
    //       breadcrumbTitle: vartranslations$.pipe(
    //         map((u) => u?.contactDetailConfiguration)
    //       ),
    //     },
    //   },
    //   {
    //     path: 'department-configuration',
    //     loadComponent: () =>
    //       import(
    //         './settings/department-config/department-config.component'
    //       ).then((mod) => mod.DepartmentConfigComponent),
    //     data: {
    //       breadcrumbTitle: vartranslations$.pipe(
    //         map((u) => u?.departmentConfiguration)
    //       ),
    //     },
    //   },
    //   {
    //     path: 'function-configuration',
    //     loadComponent: () =>
    //       import('./settings/function-config/function-config.component').then(
    //         (mod) => mod.FunctionConfigComponent
    //       ),
    //     data: {
    //       breadcrumbTitle: vartranslations$.pipe(
    //         map((u) => u?.functionConfiguration)
    //       ),
    //     },
    //   },
    //   {
    //     path: 'absence-type-configuration',
    //     loadComponent: () =>
    //       import(
    //         './settings/absence-type-config/absence-type-config.component'
    //       ).then((mod) => mod.AbsenceTypeConfigComponent),
    //     data: {
    //       breadcrumbTitle: vartranslations$.pipe(
    //         map((u) => u?.absenceTypeConfiguration)
    //       ),
    //     },
    //   },
    //   {
    //     path: 'notification-text-configuration',
    //     loadComponent: () =>
    //       import(
    //         './settings/notification-text-config/notification-text-config.component'
    //       ).then((mod) => mod.NotificationTextConfigComponent),
    //     data: {
    //       breadcrumbTitle: 'Notifications',
    //     },
    //   },
    // ],
  },
];
