import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of, switchMap, tap } from 'rxjs';
import { IshtarPermanenceService } from '../../services/project.service';
import {
  addDepartment,
  addDepartmentResolved,
  getDepartments,
  getDepartmentsResolved,
  removeDepartment,
  removeDepartmentResolved,
  updateDepartment,
  updateDepartmentResolved,
} from './department.actions';
import { updateDutiesAfterDepartmentDelete } from '../permanenceDuty/permanenceDuty.actions';
import { onDepartmentDeleteActionResolved } from '../permanenceTemplate/permanenceTemplate.actions';

@Injectable({ providedIn: 'root' })
export class DepartmentEffects {
  constructor(
    private ishtarPermanenceService: IshtarPermanenceService,
    private actions$: Actions
  ) {}

  getDepartments = createEffect(() =>
    this.actions$.pipe(
      ofType(getDepartments),
      switchMap(({ callback }) =>
        this.ishtarPermanenceService.getDepartments().pipe(
          switchMap((result) =>
            of(
              getDepartmentsResolved({
                result,
              })
            )
          ),
          tap(() => (callback ? callback() : undefined))
        )
      )
    )
  );

  updateDepartment = createEffect(() =>
    this.actions$.pipe(
      ofType(updateDepartment),
      switchMap(({ departments, callback, error }) =>
        this.ishtarPermanenceService.updateDepartment(departments).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((departments) => {
            const actions: any[] = [
              updateDepartmentResolved({
                departments,
              }),
            ];
            if (departments[0].isDeleted) {
              actions.push(
                updateDutiesAfterDepartmentDelete({
                  departmentId: departments[0].ishtarPermanenceDepartmentId,
                }),
                onDepartmentDeleteActionResolved({
                  departmentId: departments[0].ishtarPermanenceDepartmentId,
                })
              );
            }
            return actions;
          }),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  addDepartment = createEffect(() =>
    this.actions$.pipe(
      ofType(addDepartment),
      switchMap(({ departments, callback, error }) =>
        this.ishtarPermanenceService.addDepartment(departments).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((addedDepartments) =>
            of(addDepartmentResolved({ addedDepartments }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  removeDepartment = createEffect(() =>
    this.actions$.pipe(
      ofType(removeDepartment),
      switchMap(({ ishtarPermanenceDepartmentIds, callback, error }) =>
        this.ishtarPermanenceService
          .removeDepartment(ishtarPermanenceDepartmentIds)
          .pipe(
            tap((x) => (callback ? callback(x) : undefined)),
            switchMap(() =>
              of(
                removeDepartmentResolved({
                  ishtarPermanenceDepartmentIds,
                })
              )
            ),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );
}
