import { GroupUser } from 'processdelight-angular-components';
import { Lookup } from './lookup';

export class ContactDetail {
  ishtarPermanenceContactDetailId!: string;
  user?: GroupUser;
  departments?: Lookup[];
  jobTitle?: string;
  telephoneNumber?: string;
  telephoneCountry?: Lookup;
  mobileNumber?: string;
  mobileCountry?: Lookup;

  constructor(obj: Partial<ContactDetail>) {
    Object.assign(this, obj);
    if (obj.user) this.user = new GroupUser(obj.user);
  }
}
