import { DateTime } from 'luxon';
import { GroupUser } from 'processdelight-angular-components';
import { Lookup } from './lookup';

export class Absence {
  ishtarPermanenceAbsenceId!: string;
  user!: GroupUser;
  startDate?: Date;
  endDate?: Date;
  absenceType!: Lookup;
  createdOn?: Date;

  constructor(obj: Partial<Absence>) {
    Object.assign(this, obj);

    if (typeof this.startDate == 'string') {
      this.startDate = DateTime.fromFormat(this.startDate, 'd/M/yyyy H:m', {
        zone: 'utc',
      }).toJSDate();
    }

    if (typeof this.endDate == 'string') {
      this.endDate = DateTime.fromFormat(this.endDate, 'd/M/yyyy H:m', {
        zone: 'utc',
      }).toJSDate();
    }

    if (this.createdOn != undefined) this.createdOn = new Date(this.createdOn!);

    // if (typeof this.createdOn == 'string') {
    //   this.createdOn = DateTime.fromFormat(
    //     this.createdOn,
    //     'yyyy-M-d HH:mm'
    //   ).toJSDate();
    // }
  }
}
