import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of, switchMap, tap } from 'rxjs';
import { IshtarPermanenceService } from '../../services/project.service';
import {
  addApplicationNotification,
  addApplicationNotificationResolved,
  getApplicationNotifications,
  getApplicationNotificationsResolved,
  removeApplicationNotification,
  removeApplicationNotificationResolved,
  updateApplicationNotification,
  updateApplicationNotificationResolved,
} from './applicationNotification.action';
import { ApplicationNotificationDto } from 'processdelight-angular-components';

@Injectable({ providedIn: 'root' })
export class ApplicationNotificationEffects {
  constructor(
    private ishtarPermanenceService: IshtarPermanenceService,
    private actions$: Actions
  ) {}

  getApplicationNotifications = createEffect(() =>
    this.actions$.pipe(
      ofType(getApplicationNotifications),
      switchMap(({ callback }) =>
        this.ishtarPermanenceService.getApplicationNotifications().pipe(
          switchMap((result) =>
            of(
              getApplicationNotificationsResolved({
                result,
              })
            )
          ),
          tap((result) => callback?.(result.result))
        )
      )
    )
  );

  updateApplicationNotification = createEffect(() =>
    this.actions$.pipe(
      ofType(updateApplicationNotification),
      switchMap(({ applicationNotification, callback }) =>
        this.ishtarPermanenceService
          .updateApplicationNotification(applicationNotification)
          .pipe(
            switchMap((n) =>
              this.ishtarPermanenceService.getApplicationNotificationForGridById(
                n.ishtarApplicationNotificationId
              )
            ),
            tap((x) => callback?.(x)),
            switchMap((applicationNotification) =>
              of(
                updateApplicationNotificationResolved({
                  applicationNotification: new ApplicationNotificationDto(
                    applicationNotification
                  ),
                })
              )
            )
          )
      )
    )
  );

  addApplicationNotification = createEffect(() =>
    this.actions$.pipe(
      ofType(addApplicationNotification),
      switchMap(({ applicationNotification, callback }) =>
        this.ishtarPermanenceService
          .createApplicationNotification(applicationNotification)
          .pipe(
            switchMap((n) =>
              this.ishtarPermanenceService.getApplicationNotificationForGridById(
                n.ishtarApplicationNotificationId
              )
            ),
            tap((x) => callback?.(x)),
            switchMap((applicationNotification) =>
              of(
                addApplicationNotificationResolved({
                  addedApplicationNotification: new ApplicationNotificationDto(
                    applicationNotification
                  ),
                })
              )
            )
          )
      )
    )
  );

  removeApplicationNotification = createEffect(() =>
    this.actions$.pipe(
      ofType(removeApplicationNotification),
      switchMap(({ id, callback, error }) =>
        this.ishtarPermanenceService.removeApplicationNotification(id).pipe(
          tap((x) => callback?.(x)),
          switchMap((ids) =>
            of(
              removeApplicationNotificationResolved({
                ids,
              })
            )
          ),
          catchError((e) => {
            error?.(e);
            return [];
          })
        )
      )
    )
  );
}
