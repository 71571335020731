import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of, switchMap, tap, mergeMap } from 'rxjs';
import { IshtarPermanenceService } from '../../services/project.service';
import {
  getExchanges,
  getExchangesResolved,
  getPermanenceDutieForUserResolved,
} from './permanenceDuty.actions';
import {
  addExchange,
  addExchangeResolved,
  addPermanenceDuty,
  exchangeAccepted,
  exchangeAcceptedResolved,
  addPermanenceDutyResolved,
  getPermanenceDuties,
  getPermanenceDutiesResolved,
  updatePermanenceDuty,
  updatePermanenceDutyResolved,
  removePermanenceDuty,
  removePermanenceDutyResolved,
  getPermanenceDutiesForUser,
  removeRecurrencyOccurence,
  removeExchange,
  removeExchangeResolved,
  updateDutyCommentResolved,
} from './permanenceDuty.actions';
import { updateDutyComment } from './permanenceDuty.actions';

@Injectable({ providedIn: 'root' })
export class PermanenceDutyEffects {
  constructor(
    private ishtarPermanenceService: IshtarPermanenceService,
    private actions$: Actions
  ) {}

  addPermanenceDuty = createEffect(() =>
    this.actions$.pipe(
      ofType(addPermanenceDuty),
      mergeMap(({ permanenceDuty, userIds, loggedInUserId, callback, error }) =>
        this.ishtarPermanenceService
          .addPermanenceDuty(permanenceDuty, userIds)
          .pipe(
            tap((x) => (callback ? callback(x) : undefined)),
            switchMap((response) => {
              const actions: any[] = [
                addPermanenceDutyResolved({
                  addedDuties: response,
                  loggedInUserId: loggedInUserId,
                }),
              ];
              response.forEach((addedPermanenceDuty) => {
                if (addedPermanenceDuty.recurrency?.id)
                  actions.push(
                    removeRecurrencyOccurence({
                      recurrencyId: addedPermanenceDuty.recurrency.id,
                      recurrencyOccurence:
                        addedPermanenceDuty.recurrencyOccurence!,
                    })
                  );
              });
              return actions;
            }),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );

  addExchange = createEffect(() =>
    this.actions$.pipe(
      ofType(addExchange),
      mergeMap(({ exchange, permanence, isRecurrence, callback, error }) =>
        this.ishtarPermanenceService
          .addExchange(exchange, permanence, isRecurrence)
          .pipe(
            tap((x) => (callback ? callback(x) : undefined)),
            switchMap((result) => {
              const actions: any[] = [
                addExchangeResolved({
                  addedExchange: result.addedExchange,
                  exchangeDuties: result.exchangeDuties,
                  permanence: result.permanence,
                }),
              ];
              if (result.addedExchange.permanenceDuty.recurrency?.id) {
                actions.push(
                  removeRecurrencyOccurence({
                    recurrencyId:
                      result.addedExchange.permanenceDuty.recurrency?.id,
                    recurrencyOccurence:
                      result.addedExchange.permanenceDuty.recurrencyOccurence!,
                  })
                );
              }
              // result.exchangeDuties.forEach((addedPermanenceDuty) => {
              //   if (addedPermanenceDuty.recurrency?.id)
              //     actions.push(
              //       removeRecurrencyOccurence({
              //         recurrencyId: addedPermanenceDuty.recurrency.id,
              //         recurrencyOccurence:
              //           addedPermanenceDuty.recurrencyOccurence!,
              //       })
              //     );
              // });
              return actions;
            }),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );

  exchangeAccepted = createEffect(() =>
    this.actions$.pipe(
      ofType(exchangeAccepted),
      mergeMap(
        ({
          exchangeToUpdateId,
          originalDuty,
          exchangeDuty,
          splittedDuties,
          callback,
          error,
        }) =>
          this.ishtarPermanenceService
            .exchangeAccepted(
              exchangeToUpdateId,
              originalDuty,
              exchangeDuty,
              splittedDuties
            )
            .pipe(
              tap((x) => (callback ? callback(x) : undefined)),
              switchMap((result) =>
                of(
                  exchangeAcceptedResolved({
                    updatedExchange: result.updatedExchange,
                    deletedOriginalDuty: result.deletedOriginalDuty,
                    createdDuties: result.createdDuties,
                    createdExchangeDuty: result.createdExchangeDuty,
                    updatedOriginalDuty: result.updatedOriginalDuty,
                    oldExchangeId: result.oldExChangeId,
                  })
                )
              ),
              catchError((e) => {
                if (error) error(e);
                return [];
              })
            )
      )
    )
  );

  removeExchange = createEffect(() =>
    this.actions$.pipe(
      ofType(removeExchange),
      switchMap(({ exchange, callback, error }) =>
        this.ishtarPermanenceService.removeExchange(exchange).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((result) =>
            of(
              removeExchangeResolved({
                deletedExchangeId: result.deletedExchangeId,
                originalPermanence: result.originalPermanence,
              })
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  getPermanenceDuties = createEffect(() =>
    this.actions$.pipe(
      ofType(getPermanenceDuties),
      mergeMap(({ startOfMonth, endOfMonth, departments, callback, error }) =>
        this.ishtarPermanenceService
          .getPermanenceDuties(startOfMonth, endOfMonth, departments)
          .pipe(
            tap((x) => (callback ? callback(x) : undefined)),
            switchMap((data) =>
              of(
                getPermanenceDutiesResolved({
                  result: data,
                })
              )
            ),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );

  getActiveExchangesForUser = createEffect(() =>
    this.actions$.pipe(
      ofType(getExchanges),
      mergeMap(({ callback, error }) =>
        this.ishtarPermanenceService.getExchanges().pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((exchanges) =>
            of(
              getExchangesResolved({
                exchanges,
              })
            )
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  getPermanenceDutiesForUser = createEffect(() =>
    this.actions$.pipe(
      ofType(getPermanenceDutiesForUser),
      mergeMap(({ startDate, endDate, userId, callback, error }) =>
        this.ishtarPermanenceService
          .getPermanenceDutiesForUser(startDate, endDate, userId)
          .pipe(
            tap((x) => (callback ? callback(x.lastDuty) : undefined)),
            switchMap((data) =>
              of(
                getPermanenceDutieForUserResolved({
                  permanenceDuties: data.permanenceDuties,
                  lastDuty: data.lastDuty,
                })
              )
            ),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );

  // checkBlindspotsCreation = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(checkBlindspotsCreation),
  //     mergeMap(
  //       ({
  //         departmentId,
  //         templates,
  //         newItemStartDate,
  //         newItemEndDate,
  //         itemId,
  //         callback,
  //       }) =>
  //         this.ishtarPermanenceService
  //           .checkBlindSpotsCreated(
  //             departmentId,
  //             templates,
  //             newItemStartDate,
  //             newItemEndDate,
  //             itemId
  //           )
  //           .pipe(
  //             tap((x) => (callback ? callback(x) : undefined)),
  //             switchMap((data) => [
  //               getPermanenceDutiesResolved({
  //                 result: data.blindspotsCreated,
  //               }),
  //               removePermanenceDutyResolved({
  //                 ishtarPermanenceIds: data.blindspotsDeleted,
  //               }),
  //             ])
  //           )
  //     )
  //   )
  // );

  removePermanenceDuty = createEffect(() =>
    this.actions$.pipe(
      ofType(removePermanenceDuty),
      mergeMap(({ ishtarPermanenceId, callback, error }) =>
        this.ishtarPermanenceService
          .removePermanenceDuty(ishtarPermanenceId)
          .pipe(
            tap((x) => (callback ? callback(x) : undefined)),
            switchMap((ishtarPermanenceIds) =>
              of(
                removePermanenceDutyResolved({
                  ishtarPermanenceIds,
                })
              )
            ),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );

  updatePermanenceDuty = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePermanenceDuty),
      switchMap(({ permanenceDuty, userIds, callback, error }) =>
        this.ishtarPermanenceService
          .updatePermanenceDuty(permanenceDuty, userIds)
          .pipe(
            tap((x) => (callback ? callback(x) : undefined)),
            switchMap((updatedPermanenceDuty) =>
              of(updatePermanenceDutyResolved({ updatedPermanenceDuty }))
            ),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );

  updateDutyComment = createEffect(() =>
    this.actions$.pipe(
      ofType(updateDutyComment),
      switchMap(({ permanenceDuty, loggedInUserId, callback, error }) =>
        this.ishtarPermanenceService.updateDutyComment(permanenceDuty).pipe(
          tap((x) => (callback ? callback(x) : undefined)),
          switchMap((updatedPermanenceDuty) => {
            const actions: any[] = [];
            if (
              updatedPermanenceDuty.recurrency?.id &&
              !permanenceDuty.ishtarPermanenceId
            ) {
              actions.push(
                removeRecurrencyOccurence({
                  recurrencyId: updatedPermanenceDuty.recurrency.id,
                  recurrencyOccurence:
                    updatedPermanenceDuty.recurrencyOccurence!,
                }),
                addPermanenceDutyResolved({
                  addedDuties: [updatedPermanenceDuty],
                  loggedInUserId: loggedInUserId,
                })
              );
            } else {
              actions.push(
                updatePermanenceDutyResolved({
                  updatedPermanenceDuty: updatedPermanenceDuty,
                })
              );
            }
            return actions;
          }),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );
}
