import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import {
  ApplicationNotification,
  ApplicationNotificationDto,
  CreateNotificationrequest,
  Filter,
  GroupUser,
  MicrosoftAuthenticationService,
  getTodayInUTC,
} from 'processdelight-angular-components';
import { map, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Absence } from '../models/project/absence';
import { AbsenceType } from '../models/project/absenceType';
import { ContactDetail } from '../models/project/contactDetail';
import { ContactDetailParam } from '../models/project/contactDetailParam';
import { ContactDetailParamValue } from '../models/project/contactDetailParamValue';
import { Department } from '../models/project/department';
import { Exchange } from '../models/project/exchange';
import { NotificationText } from '../models/project/notificationText';
import { NotificationType } from '../models/project/notificationType';
import { PermanenceDuty } from '../models/project/permanenceDuty';
import { PermanenceTemplate } from '../models/project/permanenceTemplate';
import { PermanenceUser } from '../models/project/permanenceUser';
import { Recurrence } from '../models/project/recurrence';
import { AADUser } from '../models/user/aadUser';
import { AppConfig } from '../models/user/appConfig';
import { Group } from '../models/user/group';
import { UserSettings } from '../models/user/userSettings';

import { AbsenceContract } from '../models/contracts/absences.contract';
import { TypesContract } from '../models/contracts/types.contract';
import { UserLicenseInfo } from '../models/user/userlicenseinfo';
import {
  varlicense$,
  varnotificationtriggertypes$,
  varusersettings,
} from './startup.service';
import { ContactContract } from '../models/contracts/contacts.contract';
import { InitContract } from '../models/contracts/init.contract';
import { AbsencePeriod } from '../models/project/absencePeriod';

@Injectable({
  providedIn: 'root',
})
export class IshtarPermanenceService {
  apiBase = `${environment.ishtarFunctions}/api`;
  cacheBase = `${location.origin}/web`;
  constructor(
    private httpClient: HttpClient,
    private msal: MicrosoftAuthenticationService
  ) {}

  private createCacheEndpointUrl(path: string) {
    return new URL(`${this.cacheBase}/${path}`).toString();
  }

  private createApiEndpointUrl(path: string) {
    const url = new URL(`${this.apiBase}/${path}`);
    if (environment.ishtarFunctionsKey.trim() !== '')
      url.searchParams.append('code', environment.ishtarFunctionsKey);
    return url.toString();
  }

  sessionKeepAlive() {
    return this.httpClient.post(`${this.cacheBase}/session/keepalive`, {});
  }

  filterQuery(filters: Filter[]) {
    if (filters.length > 0) {
      let filterString = '&filter=';
      const variabelFilterString = filters
        .map((filter: Filter) => {
          switch (filter.columnName) {
            case 'NotificationType':
              filterString =
                '&expand=NotificationType&select=*,NotificationType' +
                filterString;
              return `('${filter.value}' in ${filter.columnName}/Type)`;
            case 'Department':
              filterString =
                '&expand=Department&select=*,Department' + filterString;
              return `('${filter.value}' in ${filter.columnName}/Name)`;
            case 'Function':
              filterString =
                '&expand=Function&select=*,Function' + filterString;
              return `('${filter.value}' in ${filter.columnName}/Name)`;

            default:
              return `('${filter.value}' in ${filter.columnName})`;
          }
        })
        .join(' and ');

      return filterString + variabelFilterString;
    } else {
      return '';
    }
  }

  orderByQuery(column: string, direction: string) {
    if (!column || !direction) return '';
    else return `&orderBy=${column} ${direction.toUpperCase()}`;
  }

  getLicense(tenantId: string) {
    return this.httpClient.post<UserLicenseInfo>(
      `${this.cacheBase}/session/register?tenantId=${tenantId}`,
      {}
    );
  }

  updateUserSettings(settings: UserSettings) {
    return this.httpClient
      .post<UserSettings>(
        this.createCacheEndpointUrl(`startup/userSettings`),
        settings
      )
      .pipe(
        tap((x) => varusersettings.next(x)),
        map((c) => new UserSettings(c))
      );
  }

  getStartUpData(userEmail: string, language: string) {
    return this.httpClient.get<InitContract>(
      this.createCacheEndpointUrl(
        `startup?userEmail=${userEmail}&language=${language}`
      )
    );
  }

  getTypes() {
    return this.httpClient.get<TypesContract>(
      this.createCacheEndpointUrl(`startup/types`)
    );
  }

  getAbsencesData(startDate: Date, endDate: Date) {
    const start = DateTime.fromJSDate(startDate, {
      zone: 'utc',
    }).toFormat('dd/MM/yyyy HH:mm');
    const end = DateTime.fromJSDate(endDate, {
      zone: 'utc',
    }).toFormat('dd/MM/yyyy HH:mm');
    return this.httpClient.get<AbsenceContract>(
      this.createCacheEndpointUrl(
        `absence/contract?startDate=${start}&endDate=${end}`
      )
    );
  }

  getContactDetailsData() {
    return this.httpClient.get<ContactContract>(
      this.createCacheEndpointUrl(`contacts/contract`)
    );
  }

  getDepartments() {
    return this.httpClient
      .get<Department[]>(this.createCacheEndpointUrl(`department`))
      .pipe(
        map((department) =>
          department.map((g) => new Department(this.camelcaseKeys(g)))
        )
      );
  }

  getContactDetails() {
    return this.httpClient
      .get<ContactDetail[]>(this.createCacheEndpointUrl(`contacts`))
      .pipe(
        map((result) =>
          result.map((c) => new ContactDetail(this.camelcaseKeys(c)))
        )
      );
  }

  addContactDetail(contactDetail: ContactDetail) {
    const addedContactDetail = this.capitalizeKeys(
      {
        ...contactDetail,
      },
      'Departments',
      'TelephoneNumber',
      'TelephoneCountry',
      'MobileNumber',
      'MobileCountry'
    );
    return this.httpClient
      .post<ContactDetail[]>(this.createCacheEndpointUrl(`contacts`), [
        addedContactDetail,
      ])
      .pipe(
        map((contactDetail) =>
          contactDetail.map((p) => new ContactDetail(this.camelcaseKeys(p)))
        )
      );
  }

  updateContactDetail(contactDetail: ContactDetail) {
    const updatedContactDetail = this.capitalizeKeys(
      {
        ...contactDetail,
      },
      'Departments',
      'TelephoneNumber',
      'TelephoneCountry',
      'MobileNumber',
      'MobileCountry'
    );
    return this.httpClient
      .patch<ContactDetail[]>(this.createCacheEndpointUrl(`contacts`), [
        updatedContactDetail,
      ])
      .pipe(
        map((contactDetail) =>
          contactDetail.map((p) => new ContactDetail(this.camelcaseKeys(p)))
        )
      );
  }

  removeContactDetail(ishtarPermanenceContactDetailIds: string[]) {
    return this.httpClient.delete<string[]>(
      this.createCacheEndpointUrl(`contacts`),
      {
        body: ishtarPermanenceContactDetailIds,
        headers: {
          ImpersonationUserAADId: this.msal.userId,
        },
      }
    );
  }

  addRecurrency(recurrency: Recurrence) {
    const addedRecurrency = this.capitalizeKeys(
      {
        ...recurrency,
        startDate: DateTime.fromJSDate(recurrency.startDate!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
        endDate: recurrency.endDate
          ? DateTime.fromJSDate(recurrency.endDate!, {
              zone: 'utc',
            }).toFormat('dd/MM/yyyy HH:mm')
          : null,
        fromDateTime: DateTime.fromJSDate(recurrency.fromDateTime!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
        untilDateTime: DateTime.fromJSDate(recurrency.untilDateTime!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
      },
      'Frequence',
      'Department',
      'User',
      'Backups',
      'RecurrencyDays',
      'RecurrencyMonths',
      'RecurrencyRanking'
    );
    return this.httpClient
      .post<Recurrence>(
        this.createCacheEndpointUrl(`recurrency`),
        addedRecurrency
      )
      .pipe(
        map((recurrency) => new Recurrence(this.camelcaseKeys(recurrency)))
      );
  }

  getPermanenceTemplates(departmentId?: string) {
    return this.httpClient
      .get<PermanenceTemplate[]>(
        this.createCacheEndpointUrl(`startup/template/${departmentId}`)
      )
      .pipe(
        map((permanenceTemplates) =>
          permanenceTemplates.map(
            (p) => new PermanenceTemplate(this.camelcaseKeys(p))
          )
        )
      );
  }

  addPermanenceTemplate(permanenceTemplate: PermanenceTemplate) {
    const newPermanenceTemplate = this.capitalizeKeys(
      {
        ...permanenceTemplate,
        untilTime: DateTime.fromJSDate(permanenceTemplate.untilTime!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
        fromTime: DateTime.fromJSDate(permanenceTemplate.fromTime!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
      },
      'Department',
      'FromDay',
      'UntilDay'
    );
    return this.httpClient
      .post<PermanenceTemplate[]>(
        this.createCacheEndpointUrl(`startup/template`),
        [newPermanenceTemplate]
      )
      .pipe(
        map((permanenceTemplates) =>
          permanenceTemplates.map(
            (p) => new PermanenceTemplate(this.camelcaseKeys(p))
          )
        )
      );
  }

  updatePermanenceTemplate(permanenceTemplate: PermanenceTemplate) {
    const updatedPermanenceTemplate = this.capitalizeKeys(
      {
        ...permanenceTemplate,
        untilTime: DateTime.fromJSDate(permanenceTemplate.untilTime!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
        fromTime: DateTime.fromJSDate(permanenceTemplate.fromTime!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
      },
      'Department',
      'FromDay',
      'UntilDay'
    );
    return this.httpClient
      .patch<PermanenceTemplate[]>(
        this.createCacheEndpointUrl(`startup/template`),
        [updatedPermanenceTemplate]
      )
      .pipe(
        map((permanenceTemplates) =>
          permanenceTemplates.map(
            (p) => new PermanenceTemplate(this.camelcaseKeys(p))
          )
        )
      );
  }

  removePermanenceTemplate(permanenceTemplateId: string) {
    return this.httpClient.delete<string[]>(
      this.createCacheEndpointUrl(`startup/template`),
      {
        body: [permanenceTemplateId],
        headers: {
          ImpersonationUserAADId: this.msal.userId,
        },
      }
    );
  }

  getPermanenceDuties(
    startOfMonth: Date,
    endOfMonth: Date,
    departments?: Department[]
  ) {
    const start = DateTime.fromJSDate(startOfMonth, { zone: 'utc' }).toFormat(
      'dd/MM/yyyy HH:mm'
    );
    const end = DateTime.fromJSDate(endOfMonth, { zone: 'utc' }).toFormat(
      'dd/MM/yyyy HH:mm'
    );
    const departmentIds = departments
      ?.map((d) => d.ishtarPermanenceDepartmentId)
      .join(',');
    const departmentString = departmentIds
      ? `&departments=${departmentIds}`
      : '';

    return this.httpClient
      .get<PermanenceDuty[]>(
        this.createCacheEndpointUrl(
          `permanence?startDate=${start}&endDate=${end}${departmentString}`
        )
      )
      .pipe(
        map((permanenceDuties) =>
          permanenceDuties.map((p) => new PermanenceDuty(this.camelcaseKeys(p)))
        )
      );
  }

  checkForOverlappingPermanenceDuties(
    startDate: Date,
    endDate: Date,
    departmentId: string,
    permanenceDutyId?: string,
    recurrencyId?: string,
    recurrencyOccurence?: number
  ) {
    const start = DateTime.fromJSDate(startDate, { zone: 'utc' }).toFormat(
      'dd/MM/yyyy HH:mm'
    );
    const end = DateTime.fromJSDate(endDate, { zone: 'utc' }).toFormat(
      'dd/MM/yyyy HH:mm'
    );
    const permanenceFilter = permanenceDutyId
      ? `&permanenceId=${permanenceDutyId}`
      : '';
    const recurrenceFilter = recurrencyId
      ? `&recurrenceId=${recurrencyId}&recurrenceOccurence=${recurrencyOccurence}`
      : '';
    return this.httpClient.get<boolean>(
      this.createCacheEndpointUrl(
        `permanence/checkOverlapping?startDate=${start}&endDate=${end}&departmentId=${departmentId}${permanenceFilter}${recurrenceFilter}`
      )
    );
  }

  checkForOverlappingAbsences(
    startDate: Date,
    endDate: Date,
    userId: string,
    absenceId?: string
  ) {
    const start = DateTime.fromJSDate(startDate, { zone: 'utc' }).toFormat(
      'dd/MM/yyyy HH:mm'
    );
    const end = DateTime.fromJSDate(endDate, { zone: 'utc' }).toFormat(
      'dd/MM/yyyy HH:mm'
    );
    return this.httpClient.get<boolean>(
      this.createCacheEndpointUrl(
        `absence/checkOverlapping?startDate=${start}&endDate=${end}&userId=${userId}${
          absenceId ? `&absenceId=${absenceId}` : ''
        }`
      )
    );
  }

  checkForOverlapDutyAbsence(startDate: Date, endDate: Date) {
    const start = DateTime.fromJSDate(startDate, { zone: 'utc' }).toFormat(
      'dd/MM/yyyy HH:mm'
    );
    const end = DateTime.fromJSDate(endDate, { zone: 'utc' }).toFormat(
      'dd/MM/yyyy HH:mm'
    );
    return this.httpClient.get<string[]>(
      this.createCacheEndpointUrl(
        `permanence/checkOverlapDutyAbsence?startDate=${start}&endDate=${end}`
      )
    );
  }

  checkForOverlapRecurrenceAbsence(recurrence: Recurrence) {
    const recurrenceToCheck = this.capitalizeKeys(
      {
        ...recurrence,
        startDate: DateTime.fromJSDate(recurrence.startDate!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
        endDate: recurrence.endDate
          ? DateTime.fromJSDate(recurrence.endDate!, {
              zone: 'utc',
            }).toFormat('dd/MM/yyyy HH:mm')
          : null,
        fromDateTime: DateTime.fromJSDate(recurrence.fromDateTime!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
        untilDateTime: DateTime.fromJSDate(recurrence.untilDateTime!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
      },
      'Frequence',
      'Department',
      'User',
      'Backups',
      'RecurrencyDays',
      'RecurrencyMonths',
      'RecurrencyRanking'
    );
    return this.httpClient.post<string[]>(
      this.createCacheEndpointUrl(`absence/overlappingRecurrenceAbsence`),
      recurrenceToCheck
    );
  }

  getBlindSpots(departmentId: string, startDate: Date, endDate: Date) {
    const startDateFormat = DateTime.fromJSDate(startDate, {
      zone: 'utc',
    }).toFormat('dd/MM/yyyy HH:mm');
    const endDateFormat = DateTime.fromJSDate(endDate, {
      zone: 'utc',
    }).toFormat('dd/MM/yyyy HH:mm');
    return this.httpClient
      .get<PermanenceDuty[]>(
        this.createCacheEndpointUrl(
          `permanence/dataverse?expand=Department&select=*,Department&filter=(Department/IshtarPermanenceDepartmentId eq ${departmentId}) and (User eq '') and (FromDateTime ge '${startDateFormat}' and FromDateTime le '${endDateFormat}') and (UntilDateTime ge '${startDateFormat}' and UntilDateTime le '${endDateFormat}')`
        )
      )
      .pipe(
        map((permanenceDuties) =>
          permanenceDuties.map((p) => new PermanenceDuty(this.camelcaseKeys(p)))
        )
      );
  }

  getExchanges() {
    return this.httpClient
      .get<Exchange[]>(this.createCacheEndpointUrl(`exchange`))
      .pipe(
        map((exchanges) =>
          exchanges.map((e) => new Exchange(this.camelcaseKeys(e)))
        )
      );
  }

  createApplicationNotification(
    applicationNotification: CreateNotificationrequest
  ) {
    return this.httpClient.post<ApplicationNotificationDto>(
      this.createCacheEndpointUrl(`notification`),

      applicationNotification
    );
  }

  updateApplicationNotification(
    requestNotification: CreateNotificationrequest
  ) {
    return this.httpClient.patch<ApplicationNotificationDto>(
      this.createCacheEndpointUrl(`notification`),
      requestNotification,
      {
        headers: {
          ImpersonationUserAADId: this.msal.userId,
        },
      }
    );
  }

  getApplicationNotificationById(applicationNotificationId: string) {
    return this.httpClient
      .get<ApplicationNotification>(
        this.createCacheEndpointUrl(
          `notification/${applicationNotificationId}`
        ),
        {
          headers: {
            ImpersonationUserAADId: this.msal.userId,
          },
        }
      )
      .pipe(
        map(
          (notification) =>
            new ApplicationNotification(this.camelcaseKeys(notification))
        )
      );
  }

  removeApplicationNotification(applicationNotificationId: string) {
    return this.httpClient.delete<string[]>(
      this.createCacheEndpointUrl(`notification/${applicationNotificationId}`)
    );
  }

  getApplicationNotifications() {
    return this.httpClient
      .get<ApplicationNotificationDto[]>(
        this.createCacheEndpointUrl(`notification`)
      )
      .pipe(
        map((notifications) =>
          notifications.map(
            (n) => new ApplicationNotificationDto(this.camelcaseKeys(n))
          )
        )
      );
  }

  getApplicationNotificationForGridById(applicationNotificationId: string) {
    return this.httpClient
      .get<ApplicationNotificationDto>(
        this.createCacheEndpointUrl(
          `notification/grid/${applicationNotificationId}`
        )
      )
      .pipe(map((n) => new ApplicationNotificationDto(this.camelcaseKeys(n))));
  }

  addExchange(
    exchange: Exchange,
    permanenceDuty: PermanenceDuty,
    isRecurrence: boolean
  ) {
    const triggerType = varnotificationtriggertypes$.value?.find(
      (v) => v.name == 'Created'
    )?.ishtarTriggerTypeId;
    const newExchange = this.capitalizeKeys(
      {
        ...exchange,
        startDate: DateTime.fromJSDate(exchange.startDate!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
        endDate: DateTime.fromJSDate(exchange.endDate!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
      },
      'PermanenceDuty'
    );
    const permanenceDutyToCheck = this.capitalizeKeys(
      {
        ...permanenceDuty,
        untilDateTime: DateTime.fromJSDate(permanenceDuty.untilDateTime!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
        fromDateTime: DateTime.fromJSDate(permanenceDuty.fromDateTime!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
      },
      'Department',
      'Recurrency'
    );
    return this.httpClient
      .post<{
        addedExchange: Exchange;
        exchangeDuties: PermanenceDuty[];
        permanence: PermanenceDuty;
      }>(this.createCacheEndpointUrl(`exchange/${triggerType}`), {
        exchange: newExchange,
        permanence: permanenceDutyToCheck,
        isRecurrence: isRecurrence,
      })
      .pipe(
        map((response) => ({
          exchangeDuties: response.exchangeDuties.map(
            (p) => new PermanenceDuty(this.camelcaseKeys(p))
          ),
          addedExchange: new Exchange({
            ...this.camelcaseKeys(response.addedExchange),
          }),
          permanence: new PermanenceDuty(
            this.camelcaseKeys(response.permanence)
          ),
        }))
      );
  }

  removeExchange(exchange: Exchange) {
    const exchangeToDelete = this.capitalizeKeys(
      {
        ...exchange,
        startDate: DateTime.fromJSDate(exchange.startDate!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
        endDate: DateTime.fromJSDate(exchange.endDate!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
      },
      'PermanenceDuty'
    );
    return this.httpClient
      .delete<{
        deletedExchangeId: string;
        originalPermanence: PermanenceDuty;
      }>(this.createCacheEndpointUrl(`exchange`), {
        body: exchangeToDelete,
      })
      .pipe(
        map((response) => ({
          deletedExchangeId: response.deletedExchangeId,
          originalPermanence: new PermanenceDuty(
            this.camelcaseKeys(response.originalPermanence)
          ),
        }))
      );
  }

  exchangeAccepted(
    exchangeToUpdateId: string,
    originalDuty: PermanenceDuty,
    exchangeDuty: PermanenceDuty,
    splittedDuties: PermanenceDuty[]
  ) {
    const triggerType = varnotificationtriggertypes$.value?.find(
      (v) => v.name == 'Accepted'
    )?.ishtarTriggerTypeId;
    const original = this.capitalizeKeys(
      {
        ...originalDuty,
        untilDateTime: DateTime.fromJSDate(originalDuty.untilDateTime!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
        fromDateTime: DateTime.fromJSDate(originalDuty.fromDateTime!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
      },
      'Department',
      'Recurrency'
    );
    const dutyExchange = this.capitalizeKeys(
      {
        ...exchangeDuty,
        untilDateTime: DateTime.fromJSDate(exchangeDuty.untilDateTime!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
        fromDateTime: DateTime.fromJSDate(exchangeDuty.fromDateTime!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
      },
      'Department',
      'Recurrency'
    );
    const splitted = splittedDuties.map((d) =>
      this.capitalizeKeys(
        {
          ...d,
          untilDateTime: DateTime.fromJSDate(d.untilDateTime!, {
            zone: 'utc',
          }).toFormat('dd/MM/yyyy HH:mm'),
          fromDateTime: DateTime.fromJSDate(d.fromDateTime!, {
            zone: 'utc',
          }).toFormat('dd/MM/yyyy HH:mm'),
        },
        'Department',
        'Recurrency'
      )
    );

    return this.httpClient
      .post<{
        createdDuties: PermanenceDuty[];
        createdExchangeDuty: PermanenceDuty;
        updatedOriginalDuty: PermanenceDuty;
        deletedOriginalDuty: string;
        updatedExchange: Exchange;
        oldExchangeId: string;
      }>(this.createCacheEndpointUrl(`exchange/accepted/${triggerType}`), {
        exchangeToUpdateId: exchangeToUpdateId,
        originalDuty: original,
        exchangeDuty: dutyExchange,
        splittedDuties: splitted,
      })
      .pipe(
        map((response) => ({
          createdDuties: response.createdDuties.map(
            (p) => new PermanenceDuty(this.camelcaseKeys(p))
          ),
          createdExchangeDuty: new PermanenceDuty(
            this.camelcaseKeys(response.createdExchangeDuty)
          ),
          updatedOriginalDuty: new PermanenceDuty(
            this.camelcaseKeys(response.updatedOriginalDuty)
          ),
          deletedOriginalDuty: response.deletedOriginalDuty,
          updatedExchange: new Exchange({
            ...this.camelcaseKeys(response.updatedExchange),
            permanenceDuty: new PermanenceDuty(
              this.camelcaseKeys(response.updatedOriginalDuty)
            ),
          }),
          oldExChangeId: response.oldExchangeId,
        }))
      );
  }

  getPermanenceDutiesForUser(startDate: Date, endDate: Date, userId: string) {
    const parsedStartDate = DateTime.fromJSDate(startDate, {
      zone: 'utc',
    }).toFormat('dd/MM/yyyy HH:mm');
    const parsedEndDate = DateTime.fromJSDate(endDate, {
      zone: 'utc',
    }).toFormat('dd/MM/yyyy HH:mm');
    return this.httpClient
      .get<{ permanenceDuties: PermanenceDuty[]; lastDuty: boolean }>(
        this.createCacheEndpointUrl(
          `permanence/dutiesForUser?startDate=${parsedStartDate}&endDate=${parsedEndDate}&userId=${userId}`
        )
      )
      .pipe(
        map((response) => ({
          permanenceDuties: response.permanenceDuties.map(
            (p) => new PermanenceDuty(this.camelcaseKeys(p))
          ),
          lastDuty: response.lastDuty,
        }))
      );
  }

  addPermanenceDuty(permanenceDuty: PermanenceDuty, permanenceUsers: string[]) {
    const triggerType = varnotificationtriggertypes$.value?.find(
      (v) => v.name == 'Created'
    )?.ishtarTriggerTypeId;
    const newPermanenceDuty = this.capitalizeKeys(
      {
        ...permanenceDuty,
        untilDateTime: DateTime.fromJSDate(permanenceDuty.untilDateTime!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
        fromDateTime: DateTime.fromJSDate(permanenceDuty.fromDateTime!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
      },
      'Department',
      'Recurrency'
    );
    return this.httpClient
      .post<PermanenceDuty[]>(
        this.createCacheEndpointUrl(
          `permanence/addPermanence?triggerType=${triggerType}`
        ),
        { permanence: newPermanenceDuty, permanenceUserIds: permanenceUsers }
      )
      .pipe(
        map((permanenceDuties) =>
          permanenceDuties.map((p) => new PermanenceDuty(this.camelcaseKeys(p)))
        )
      );
  }

  updatePermanenceDuty(
    permanenceDuty: PermanenceDuty,
    permanenceUsers: string[]
  ) {
    const triggerType = varnotificationtriggertypes$.value?.find(
      (v) => v.name == 'Updated'
    )?.ishtarTriggerTypeId;
    const updatedPermanenceDuty = this.capitalizeKeys(
      {
        ...permanenceDuty,
        untilDateTime: DateTime.fromJSDate(permanenceDuty.untilDateTime!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
        fromDateTime: DateTime.fromJSDate(permanenceDuty.fromDateTime!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
      },
      'Department',
      'Recurrency'
    );
    delete updatedPermanenceDuty.Id;
    return this.httpClient
      .patch<PermanenceDuty>(
        this.createCacheEndpointUrl(
          `permanence/updatePermanence?triggerType=${triggerType}`
        ),
        {
          permanence: updatedPermanenceDuty,
          permanenceUserIds: permanenceUsers,
        }
      )
      .pipe(
        map(
          (permanenceDuty) =>
            new PermanenceDuty(this.camelcaseKeys(permanenceDuty))
        )
      );
  }

  updateDutyComment(permanence: PermanenceDuty) {
    const permanenceDuty = this.capitalizeKeys(
      {
        ...permanence,
        untilDateTime: DateTime.fromJSDate(permanence.untilDateTime!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
        fromDateTime: DateTime.fromJSDate(permanence.fromDateTime!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
      },
      'Department',
      'Recurrency'
    );
    delete permanenceDuty.Id;
    return this.httpClient
      .patch<PermanenceDuty[]>(
        this.createCacheEndpointUrl(`permanence/updateCommentDuty`),
        permanenceDuty
      )
      .pipe(
        map(
          ([permanenceDuty]) =>
            new PermanenceDuty(this.camelcaseKeys(permanenceDuty))
        )
      );
  }

  removePermanenceDuty(ishtarPermanenceId: string) {
    return this.httpClient.delete<string[]>(
      this.createCacheEndpointUrl(
        `permanence/deletePermanence/${ishtarPermanenceId}`
      )
    );
  }

  getRecurrencies() {
    return this.httpClient
      .get<Recurrence[]>(this.createCacheEndpointUrl(`recurrency`))
      .pipe(
        map((recurrencies) =>
          recurrencies.map((r) => new Recurrence(this.camelcaseKeys(r)))
        )
      );
  }

  updateRecurrence(recurrency: Recurrence, startDate: Date, endDate: Date) {
    const parsedStartDate = DateTime.fromJSDate(startDate, {
      zone: 'utc',
    }).toFormat('dd/MM/yyyy HH:mm');
    const parsedEndDate = DateTime.fromJSDate(endDate, {
      zone: 'utc',
    }).toFormat('dd/MM/yyyy HH:mm');
    const parsedRecurrencyStartDate = DateTime.fromJSDate(
      recurrency.startDate!,
      {
        zone: 'utc',
      }
    ).toFormat('dd/MM/yyyy HH:mm');
    const parsedRecurrencyEndDate = recurrency.endDate
      ? DateTime.fromJSDate(recurrency.endDate!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm')
      : null;
    const updatedRecurrence = this.capitalizeKeys(
      {
        ...recurrency,
        startDate: parsedRecurrencyStartDate,
        endDate: parsedRecurrencyEndDate,
        fromDateTime: DateTime.fromJSDate(recurrency.fromDateTime!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
        untilDateTime: DateTime.fromJSDate(recurrency.untilDateTime!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
      },
      'Frequence',
      'Department',
      'User',
      'Backups',
      'RecurrencyDays',
      'RecurrencyMonths',
      'RecurrencyRanking'
    );
    return this.httpClient
      .patch<{
        updatedRecurrency: Recurrence;
        recurrencyDuties: PermanenceDuty[];
      }>(
        this.createCacheEndpointUrl(
          `recurrency?startDate=${parsedStartDate}&endDate=${parsedEndDate}`
        ),
        updatedRecurrence
      )
      .pipe(
        map((response) => {
          return {
            updatedRecurrency: new Recurrence(
              this.camelcaseKeys(response.updatedRecurrency)
            ),
            recurrencyDuties: response.recurrencyDuties.map(
              (p) => new PermanenceDuty(this.camelcaseKeys(p))
            ),
          };
        })
      );
  }

  removeRecurrence(ishtarPermanenceRecurrencyId: string) {
    return this.httpClient
      .delete<{
        deletedPermanenceIds: string[];
        deletedRecurrencyId: string;
      }>(
        this.createCacheEndpointUrl(
          `recurrency/${ishtarPermanenceRecurrencyId}`
        ),
        {
          headers: {
            ImpersonationUserAADId: this.msal.userId,
          },
        }
      )
      .pipe(
        map((response) => ({
          deletedPermanenceIds: response.deletedPermanenceIds,
          deletedRecurrencyId: response.deletedRecurrencyId,
        }))
      );
  }

  getAbsences(startDate: Date, endDate: Date) {
    const parsedStartDate = DateTime.fromJSDate(startDate, {
      zone: 'utc',
    }).toFormat('dd/MM/yyyy HH:mm');
    const parsedEndDate = DateTime.fromJSDate(endDate, {
      zone: 'utc',
    }).toFormat('dd/MM/yyyy HH:mm');
    return this.httpClient
      .get<Absence[]>(
        this.createCacheEndpointUrl(
          `absence/dataverse?expand=AbsenceType&select=*,AbsenceType&filter=(StartDate ge '${parsedStartDate}' and StartDate le '${parsedEndDate}') or (EndDate ge '${parsedStartDate}' and EndDate le '${parsedEndDate}')`
        )
      )
      .pipe(
        map((absences) =>
          absences.map((a) => new Absence(this.camelcaseKeys(a)))
        )
      );
  }

  getAbsencesForUser() {
    return this.httpClient
      .get<Absence[]>(this.createCacheEndpointUrl(`absence/absencesForUser`))
      .pipe(
        map((absences) =>
          absences.map((a) => new Absence(this.camelcaseKeys(a)))
        )
      );
  }

  addAbsences(absence: Absence) {
    const newAbsence = this.capitalizeKeys(
      {
        ...absence,
        startDate: DateTime.fromJSDate(absence.startDate!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
        endDate: DateTime.fromJSDate(absence.endDate!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
      },
      'AbsenceType'
    );

    delete newAbsence.CreatedOn;
    return this.httpClient
      .post<Absence>(this.createCacheEndpointUrl(`absence`), newAbsence)
      .pipe(
        map((addedAbsence) => new Absence(this.camelcaseKeys(addedAbsence)))
      );
  }

  updateAbsences(absence: Absence) {
    const updatedAbsence = this.capitalizeKeys(
      {
        ...absence,
        startDate: DateTime.fromJSDate(absence.startDate!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
        endDate: DateTime.fromJSDate(absence.endDate!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
      },
      'AbsenceType'
    );
    delete updatedAbsence.CreatedOn;
    return this.httpClient
      .patch<Absence>(this.createCacheEndpointUrl(`absence`), updatedAbsence)
      .pipe(map((absence) => new Absence(this.camelcaseKeys(absence))));
  }

  removeAbsences(ishtarPermanenceAbsenceId: string) {
    return this.httpClient.delete<string[]>(
      this.createCacheEndpointUrl(`absence/${ishtarPermanenceAbsenceId}`)
    );
  }

  getContactDetailParams() {
    return this.httpClient
      .get<ContactDetailParam[]>(this.createCacheEndpointUrl(`contactparams`))
      .pipe(
        map((contactDetailParams) =>
          contactDetailParams.map(
            (c) => new ContactDetailParam(this.camelcaseKeys(c))
          )
        )
      );
  }

  addContactDetailParam(contactDetailParam: ContactDetailParam) {
    const newContactDetailParam = this.capitalizeKeys(contactDetailParam);
    return this.httpClient
      .post<ContactDetailParam[]>(
        this.createCacheEndpointUrl(`contactparams`),
        [newContactDetailParam]
      )
      .pipe(
        map(
          (contactDetailParam) =>
            new ContactDetailParam(this.camelcaseKeys(contactDetailParam[0]))
        )
      );
  }

  updateContactDetailParam(contactDetailParam: ContactDetailParam) {
    const updatedContactDetailParam = this.capitalizeKeys(contactDetailParam);
    return this.httpClient
      .patch<ContactDetailParam[]>(
        this.createCacheEndpointUrl(`contactparams`),
        [updatedContactDetailParam]
      )
      .pipe(
        map(
          (contactDetailParam) =>
            new ContactDetailParam(this.camelcaseKeys(contactDetailParam[0]))
        )
      );
  }

  getContactDetailParamValues() {
    return this.httpClient
      .get<ContactDetailParamValue[]>(
        this.createCacheEndpointUrl(`contactparamvalues`)
      )
      .pipe(
        map((contactDetailParamValues) =>
          contactDetailParamValues.map(
            (c) => new ContactDetailParamValue(this.camelcaseKeys(c))
          )
        )
      );
  }

  updateContactDetailParamValue(
    contactDetailParamValue: ContactDetailParamValue
  ) {
    const updatedContactDetailParamValue = this.capitalizeKeys(
      {
        ...contactDetailParamValue,
      },
      'ContactDetail',
      'Param',
      'ParamValue'
    );
    return this.httpClient
      .patch<ContactDetail[]>(
        this.createCacheEndpointUrl(`contactparamvalues`),
        [updatedContactDetailParamValue]
      )
      .pipe(
        map(
          (contactDetailParamValue) =>
            new ContactDetailParamValue(
              this.camelcaseKeys(contactDetailParamValue[0])
            )
        )
      );
  }

  addContactDetailParamValue(contactDetailParamValue: ContactDetailParamValue) {
    const newContactDetailParamValue = this.capitalizeKeys(
      {
        ...contactDetailParamValue,
      },
      'ContactDetail',
      'Param',
      'ParamValue'
    );
    return this.httpClient
      .post<ContactDetailParamValue[]>(
        this.createCacheEndpointUrl(`contactparamvalues`),
        [newContactDetailParamValue]
      )
      .pipe(
        map(
          (contactDetailParamValue) =>
            new ContactDetailParamValue(
              this.camelcaseKeys(contactDetailParamValue[0])
            )
        )
      );
  }

  removeContactDetailParamValues(paramValueIds: string[]) {
    return this.httpClient.delete<string[]>(
      this.createCacheEndpointUrl(`contactparamvalues`),
      {
        body: paramValueIds,
        headers: {
          ImpersonationUserAADId: this.msal.userId,
        },
      }
    );
  }

  removeContactDetailParam(paramId: string) {
    return this.httpClient.delete<string>(
      this.createCacheEndpointUrl(`contactparams/${paramId}`),
      {
        headers: {
          ImpersonationUserAADId: this.msal.userId,
        },
        responseType: 'text' as 'json',
      }
    );
  }

  updateDepartment(departments: Department[]) {
    const updatedDepartments = departments.map((s) => this.capitalizeKeys(s));
    return this.httpClient
      .patch<Department[]>(
        this.createCacheEndpointUrl(`department`),
        updatedDepartments
      )
      .pipe(
        map((departments) =>
          departments.map((p) => new Department(this.camelcaseKeys(p)))
        )
      );
  }

  addDepartment(departments: Department[]) {
    const newDepartments = departments.map((s) => this.capitalizeKeys(s));
    return this.httpClient
      .post<Department[]>(
        this.createCacheEndpointUrl(`department`),
        newDepartments
      )
      .pipe(
        map((departments) =>
          departments.map((s) => new Department(this.camelcaseKeys(s)))
        )
      );
  }

  removeDepartment(ishtarPermanenceDepartmentIds: string[]) {
    return this.httpClient.delete<string[]>(
      this.createCacheEndpointUrl(`department`),
      {
        body: ishtarPermanenceDepartmentIds,
        headers: {
          ImpersonationUserAADId: this.msal.userId,
        },
      }
    );
  }

  getAbsenceTypes() {
    return this.httpClient
      .get<AbsenceType[]>(this.createCacheEndpointUrl(`absenceType`))
      .pipe(
        map((absenceType) =>
          absenceType.map((g) => new AbsenceType(this.camelcaseKeys(g)))
        )
      );
  }

  updateAbsenceType(absenceTypes: AbsenceType[]) {
    const updatedAbsenceType = absenceTypes.map((s) =>
      this.capitalizeKeys(s, 'type', 'color')
    );
    return this.httpClient
      .patch<AbsenceType[]>(
        this.createCacheEndpointUrl(`absenceType`),
        updatedAbsenceType
      )
      .pipe(
        map((absenceTypes) =>
          absenceTypes.map((p) => new AbsenceType(this.camelcaseKeys(p)))
        )
      );
  }

  addAbsenceType(absenceTypes: AbsenceType[]) {
    const newAbsenceTypes = absenceTypes.map((s) => this.capitalizeKeys(s));
    return this.httpClient
      .post<AbsenceType[]>(
        this.createCacheEndpointUrl(`absenceType`),
        newAbsenceTypes
      )
      .pipe(
        map((absenceTypes) =>
          absenceTypes.map((s) => new AbsenceType(this.camelcaseKeys(s)))
        )
      );
  }

  removeAbsenceType(ishtarPermanenceAbsenceTypeIds: string[]) {
    return this.httpClient.delete<string[]>(
      this.createCacheEndpointUrl(`absenceType`),
      {
        body: ishtarPermanenceAbsenceTypeIds,
      }
    );
  }

  getAbsencePeriods() {
    return this.httpClient
      .get<AbsencePeriod[]>(this.createCacheEndpointUrl(`absencePeriod`))
      .pipe(
        map((p) =>
          p.map((a: AbsencePeriod) => new AbsencePeriod(this.camelcaseKeys(a)))
        )
      );
  }

  updateAbsencePeriod(absencePeriods: AbsencePeriod[]) {
    const updatedAbsencePeriods = absencePeriods.map((s) =>
      this.capitalizeKeys({
        ...s,
        startDate: DateTime.fromJSDate(s.startDate!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
        endDate: DateTime.fromJSDate(s.endDate!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
      })
    );
    return this.httpClient
      .patch<AbsencePeriod[]>(
        this.createCacheEndpointUrl(`absencePeriod`),
        updatedAbsencePeriods
      )
      .pipe(
        map((absencePeriods) =>
          absencePeriods.map((a) => new AbsencePeriod(this.camelcaseKeys(a)))
        )
      );
  }

  addAbsencePeriod(absencePeriods: AbsencePeriod[]) {
    const newAbsencePeriods = absencePeriods.map((s) =>
      this.capitalizeKeys({
        ...s,
        startDate: DateTime.fromJSDate(s.startDate!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
        endDate: DateTime.fromJSDate(s.endDate!, {
          zone: 'utc',
        }).toFormat('dd/MM/yyyy HH:mm'),
      })
    );
    return this.httpClient
      .post<AbsencePeriod[]>(
        this.createCacheEndpointUrl(`absencePeriod`),
        newAbsencePeriods
      )
      .pipe(
        map((absencePeriods) =>
          absencePeriods.map((a) => new AbsencePeriod(this.camelcaseKeys(a)))
        )
      );
  }

  removeAbsencePeriod(ishtarPermanenceAbsencePeriodIds: string[]) {
    return this.httpClient.delete<string[]>(
      this.createCacheEndpointUrl(`absencePeriod`),
      {
        body: ishtarPermanenceAbsencePeriodIds,
        headers: {
          ImpersonationUserAADId: this.msal.userId,
        },
      }
    );
  }

  camelcaseKeys(obj: any): any {
    if (Array.isArray(obj)) return [...obj.map((o) => this.camelcaseKeys(o))];
    else if (obj instanceof Object)
      return Object.entries(obj).reduce(
        (acc, e) => ({
          ...acc,

          [e[0].charAt(0).toLowerCase() + e[0].slice(1)]: this.camelcaseKeys(
            e[1]
          ),
        }),

        {}
      );
    else return obj;
  }

  capitalizeKeys(obj: any, ...ignoredProperties: string[]): any {
    const ignoredPropertiesLower = ignoredProperties.map((p) =>
      p.toLowerCase()
    );

    if (Array.isArray(obj))
      return [...obj.map((o) => this.capitalizeKeys(o, ...ignoredProperties))];
    else if (obj instanceof Object)
      return Object.entries(obj).reduce(
        (acc, e) => ({
          ...acc,

          [e[0].charAt(0).toUpperCase() + e[0].slice(1)]:
            ignoredPropertiesLower.includes(e[0].toLowerCase())
              ? e[1]
              : this.capitalizeKeys(e[1], ...ignoredProperties),
        }),
        {}
      );
    else return obj;
  }
}
