import { Lookup } from './lookup';

export class ContactDetailParamValue {
  ishtarPermanenceContactDetailParamValueId!: string;
  contactDetail?: Lookup;
  param!: Lookup;
  paramValue?: string;

  constructor(obj: Partial<ContactDetailParamValue>) {
    Object.assign(this, obj);
  }
}
